import React, { useMemo } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

const CustomImage = ({ src, srcSet, alt = "Image", isLazy = true, ...rest }) => {
    const data = useStaticQuery(graphql`
        {
            images: allFile(
                filter: { sourceInstanceName: { eq: "images" }, extension: { ne: "svg" } }
            ) {
                edges {
                    node {
                        relativePath
                        extension
                        publicURL
                        childImageSharp {
                            gatsbyImageData(
                                quality: 100
                                placeholder: NONE
                                formats: [AUTO, WEBP]
                            )
                        }
                    }
                }
            }
        }
    `);

    const match = useMemo(() => {
        const srcWithoutHashParts = [];
        const firstPart = src.split(".")[0].split("-")
        const secondPart = src.split(".")[1]

        for(let i = 0; i < (firstPart.length - 1); i++) {
            srcWithoutHashParts.push(firstPart[i])
        }

        const srcWithoutHash = `${srcWithoutHashParts.join("-")}.${secondPart}`.replace("/static/", "")

        return data.images.edges.find(({ node }) => srcWithoutHash === node.relativePath.split("/")[node.relativePath.split("/").length - 1])
    }, [data, src]);

    if (!match) {
        return (
            <img
                src={src}
                {...rest}
                style={{ zIndex: 1 }}
                alt={alt}
                srcSet={srcSet}
                loading={isLazy ? "lazy" : null}
            />
        );
    }

    const { node: { childImageSharp, publicURL, extension } = {} } = match;

    if (extension === "svg" || !childImageSharp) {
        return (
            <img
                src={publicURL}
                {...rest}
                style={{ zIndex: 1 }}
                alt={alt}
                srcSet={srcSet}
                loading={isLazy ? "lazy" : null}
            />
        );
    }

    return (
        <GatsbyImage
            image={childImageSharp.gatsbyImageData}
            {...rest}
            srcSet={srcSet}
            alt={alt}
            loading="lazy"
            place
        />
    );
};

export default CustomImage;
