import React from 'react';
import SectionWrapper from './CommonSections/SectionWrapper';
import TextDropDown from './TextDropDown';
import useWindow from '../hooks/useWindow';

function FAQSection({ FAQItems, title }) {
  const { isMobile } = useWindow();
  let content = FAQItems;

  if (typeof FAQItems === "function") {
    content = FAQItems();
  }

  return (
    <div style={{ background: '#FFF3F3' }}>
      <SectionWrapper>
        <div className="heading__container">
          {isMobile ? (
            <h2 className='heading__container_h2'>
              {title}
            </h2>
          ) : (
            <h1 style={{ textAlign: "center" }}className='faqs_h2'>
              {title}
            </h1>
          )}
        </div>
        <div className="FAQ-section__items-container">
          {content.map((item) => (
            <TextDropDown key={Math.random()} title={item.title} description={item.description} animated />
          ))}
        </div>
      </SectionWrapper>
    </div>
  );
}

export default FAQSection;
