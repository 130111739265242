import { useEffect, useState  } from "react"
import useWindow from "./useWindow";

function useSliderHeight(desktopHeight, mobileHeight, xsMobileHeight) {
  const [sliderHeight, setSliderHeight] = useState(null)
  const { isMobile, isXsMobile } = useWindow();

  useEffect(() =>  {
    if(isXsMobile) {
      setSliderHeight(xsMobileHeight)
    }
    else if(isMobile) {
      setSliderHeight(mobileHeight)
    }
    else {
      setSliderHeight(desktopHeight)
    }
  }, [desktopHeight, mobileHeight, xsMobileHeight, isMobile, isXsMobile])

  return sliderHeight
}

export default useSliderHeight
