import React from 'react';
import useWindow from '../../../hooks/useWindow';
import CustomImage from '../../Image/Image';
import { MainDescription, SecondaryDescription } from '../../Typography';

function StepsItem({ image, index, itemTitle, description, height }) {
  const { isMobile } = useWindow();

  return (
    <div className="steps-section__item" style={{height: (isMobile === false) && height}}>
      <CustomImage src={image} alt={`step ${index}  image`} />
      <div className="steps-section__item-text">
        <MainDescription>
          {itemTitle}
        </MainDescription>
        <SecondaryDescription fontSize={isMobile ? '18px' : ''}>{description}</SecondaryDescription>
      </div>
    </div>
  );
}

export default StepsItem;
