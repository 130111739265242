import React, { useMemo, useState } from 'react';
import { MainDescription, SecondaryDescription } from './Typography';
import IconArrowDown from '../assets/images/icons/other/arrow-down.inline.svg';
import IconArrowDownNarrow from '../assets/images/icons/other/arrow-down-narrow.inline.svg';

function TextDropDown({ title, description, animated }) {
  const [isOpen, setIsOpen] = useState(false);

  const textDropdownClassName = useMemo(() => {
    const basicClassName = 'text-dropdown';

    return `${basicClassName} ${isOpen ? basicClassName + '-open' : ''} ${
      animated ? basicClassName + '-animated' : ''
    }`;
  }, [animated, isOpen]);

  return (
    <div
      className={textDropdownClassName}
      onClick={() => setIsOpen((prevState) => !prevState)}
    >
      <div
        className={`text-dropdown__item-container ${
          isOpen ? 'text-dropdown__item-container-open' : ''
        }`}
      >
        <div className="text-dropdown-title">
          <SecondaryDescription>{title}</SecondaryDescription>
          {animated ? <IconArrowDown /> : <IconArrowDownNarrow />}
        </div>
        <div className="text-dropdown-description">
          <MainDescription>{description}</MainDescription>
        </div>
      </div>
    </div>
  );
}

export default TextDropDown;
