import React, { useEffect, useRef } from 'react';
import TrusPilotLogo from '../assets/images/icons/other/trustpilot-logo.png';
import TrusPilotStars from '../assets/images/icons/other/trustpilot-stars-new.png';
import { SecondaryDescription } from './Typography';

function TruspilotWidget({ customWidget }) {
  const trustpilotRef = useRef(null);

  useEffect(() => {
    if (!window.Trustpilot) return;

    window.Trustpilot.loadFromElement(trustpilotRef.current, true);
  }, []);

  if (customWidget) {
    return (
      <div className="trustpilot__container">
        <img className="trustpilot-stars" src={TrusPilotStars} alt="trustpilto widget" />
        <div className="trustpilot__text-container">
          <SecondaryDescription fontWeight={500}>
            4.4 out of 5 based on 428 reviews on
          </SecondaryDescription>
          <img className="trustpilot-logo" src={TrusPilotLogo} alt='trustpilot'/>
        </div>
      </div>
    );
  }

  return (
    <div
      ref={trustpilotRef}
      className="trustpilot-widget"
      data-locale="en-US"
      data-template-id="5419b6a8b0d04a076446a9ad"
      data-businessunit-id="6290e880611c3ff2ea1027eb"
      data-style-height="24px"
      data-style-width="100%"
      data-theme="light"
      data-min-review-count="50"
      data-style-alignment="center"
    >
      <a
        href="https://www.trustpilot.com/review/sprive.com"
        target="_blank"
        rel="noopener noreferrer"
      >
        Trustpilot
      </a>
    </div>
  );
}

export default TruspilotWidget;
