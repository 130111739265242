import React from 'react'
import useWindow from '../hooks/useWindow'
import { SecondaryTitle, SubTitle, SubTitleSmall } from './Typography'

function Heading({title, subtitle, subtitleSmall, showOnMobile, useH1, useSubtitleH2}) {
  const { isMobile } = useWindow()

  return (
    <div className="heading__container">
      {useH1 ? (
        <h1>
          {title}
        </h1>
      ) : (
        <SecondaryTitle>
            {title}
        </SecondaryTitle>
      )}
        {(!isMobile || showOnMobile) && (
          <>
            {subtitleSmall ? (
              <SubTitleSmall>
                {subtitle}
              </SubTitleSmall>
            ) : (
              <>
                {useSubtitleH2 ? (
                  <h2>
                    {subtitle}
                  </h2>
                ) : (
                  <SubTitle>
                    {subtitle}
                  </SubTitle>
                )}
              </>
            )}
          </>
        )}
    </div>
  )
}

export default Heading
