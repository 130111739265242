import React, { useCallback } from 'react';
import { useMemo } from 'react';
import Heading from '../../Heading';
import SectionWrapper from '../SectionWrapper';
import StepsItem from './StepsItem';

function StepsSection({
  title,
  subtitle,
  stepsData,
  background,
  isLeft,
  height,
  isFirstSection,
  showSubtitle,
  heading,
  showDigit = false,
}) {
  const generateItems = useCallback(() => {
    const components = [];

    stepsData.forEach((item, index) => {
      components.push(
        <StepsItem
          key={index}
          image={item.image}
          itemTitle={item.title}
          description={item.description}
          index={index + 1}
          height={height}
        />
      );
    });

    return components;
  }, [stepsData]);

  const generateSectionClassName = useMemo(() => {
    const basicClassName = 'steps-section';

    return isLeft ? `${basicClassName} ${basicClassName}-left` : basicClassName;
  }, [isLeft]);

  return (
    <div className={generateSectionClassName} style={{ background }}>
      <SectionWrapper isFirstSection={isFirstSection}>
        <Heading
          title={title}
          subtitle={subtitle}
          showOnMobile={showSubtitle}
          useH1={heading}
          useSubtitleH2={heading}
        />
         {/* {showDigit &&   <Digit />} */}

        <div className="steps-section__items-container">{generateItems()}</div>
      </SectionWrapper>
    </div>
  );
}

export default StepsSection;
